// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.animal-list_cell__j5Pm8 {
  padding: 6px !important;
}

.animal-list_custModel__l5HTE {
  height: 95vh;
  overflow-y: scroll;
}`, "",{"version":3,"sources":["webpack://./src/pages/Muzzle1/animal-list.module.css"],"names":[],"mappings":"AAAA;EACE,uBAAuB;AACzB;;AAEA;EACE,YAAY;EACZ,kBAAkB;AACpB","sourcesContent":[".cell {\n  padding: 6px !important;\n}\n\n.custModel {\n  height: 95vh;\n  overflow-y: scroll;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"cell": `animal-list_cell__j5Pm8`,
	"custModel": `animal-list_custModel__l5HTE`
};
export default ___CSS_LOADER_EXPORT___;
